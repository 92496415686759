<template>
  <div>
    <h3>{{ $t('salary.title') }}</h3>
    <br />
    <v-divider></v-divider> <br />
    <v-row>
      <v-col md="3">
        <v-card>
          <v-card-text>
            <v-btn
              v-for="grp in groups"
              :color="grp.id == group.id ? 'info' : ''"
              v-bind:key="grp.id"
              block
              :outlined="grp.id == group.id ? false : true"
              class=" mb-3"
              @click="selGroup(grp)"
            >
              {{ grp.name }}
            </v-btn>

            <v-btn block outlined color="success" @click="edit({})" class="mb-3">
              <v-icon>fa-plus</v-icon> Добавить группу
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="9">
        <v-card>
          <v-card-title>
            <v-btn color="info" outlined @click="edit(group)" small v-if="!!group">
              <v-icon small>fa-pencil</v-icon> Изменить группу
            </v-btn>
            <v-spacer></v-spacer>
            {{ group.name }}
            <v-spacer></v-spacer>
            <v-btn color="success" @click="addSalary()" small v-if="!!group">
              <v-icon small>fa-plus</v-icon> {{ $t('all.add') }}
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="text-left">{{ $t('salary.description') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in salarys" :key="item.id" @click="editSalary(item)">
                  <td>{{ item.name }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <modal_edit v-if="modal" v-bind:view="modalView" v-on:close="modalClose"></modal_edit>
  </div>
</template>

<script>
//TODO: придумать как переводить название настройки зарплаты

import modal_edit from './salary_edit.vue'

import { ref, reactive, watch } from '@vue/composition-api'
import libStore from './../../store'
import axios from 'axios'
import { useTest } from './test.js'
export default {
  setup() {
    const modalView = ref('')
    const modal = ref('')
    const salarys = ref([])
    const store = libStore
    const { groupLoad, groups, group, selGroup, edit } = useTest()
    // console.log('🚀 ~ file: salary.vue ~ line 85 ~ setup ~ sGrp', sGrp)

    const salaryLoad = id => {
      store.commit('loadingStartShow', true)
      axios
        .postForm(store.state.apiUrl + 'salary/salary', {
          group: id,
        })
        .then(
          response => {
            store.commit('loadingStartShow', false)
            if (!response.data.err) {
              salarys.value = response.data
            }
          },
          err => {
            console.log(err)
          },
        )
    }
    const addSalary = () => {
      modalView.value = { group: group.value.id }
      modal.value = true
    }
    const editSalary = e => {
      modalView.value = e
      modal.value = true
    }
    const modalClose = prm => {
      modal.value = ''
      if (prm == 'reload') {
        salaryLoad(group.value.id)
      }
    }

    watch(group, newValue => {
      salaryLoad(newValue.id)
    })
    return {
      group,
      groups,
      salarys,
      modalView,
      modal,
      selGroup,
      groupLoad,
      salaryLoad,
      addSalary,
      editSalary,
      modalClose,
      edit,
    }
  },
  components: {
    modal_edit,
  },
}
</script>

<style></style>
