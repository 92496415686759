<template>
  <div>
    <v-dialog v-model="dialog" scrollable persistent>
      <v-card>
        <v-card-title class="headline" v-if="view.id">
          {{ $t('salary.edittitle') }}
        </v-card-title>
        <v-card-title class="headline" v-if="!view.id">
          {{ $t('salary.addtitle') }}
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid" lazy-validation>
            <v-row>
              <v-col>
                <v-select
                  v-bind:items="groups"
                  v-model="data.group"
                  :label="$t('salary.forname')"
                  bottom
                  item-text="name"
                  item-value="id"
                >
                </v-select>
              </v-col>
              <v-col>
                <v-select
                  v-bind:items="tpes"
                  v-model="data.tpe"
                  :label="$t('salary.tpe')"
                  bottom
                  item-text="name"
                  item-value="id"
                  return-object
                >
                </v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="data.rate"
                  :label="
                    rate_tpe == 1 || data.tpe == undefined || !data.tpe.flg_ratetpe
                      ? $t('salary.costfix')
                      : $t('salary.costprc')
                  "
                >
                  <div slot="append-outer" v-show="!!data.tpe && data.tpe.flg_ratetpe">
                    <v-btn-toggle rounded dense v-model="rate_tpe" mandatory>
                      <v-btn small value="2"> % </v-btn>
                      <v-btn small value="1"> руб. </v-btn>
                    </v-btn-toggle>
                  </div>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div v-show="!!data.tpe && data.tpe.flg_status">
                  {{ $t('salary.status') }}:({{ !!data.status ? data.status.length : 0 }})<v-btn
                    small
                    @click="selStForm()"
                  >
                    {{ $t('all.select') }}</v-btn
                  >
                  <br />
                </div>

                <v-select
                  v-bind:items="job_tpes"
                  v-model="data.job_tpe"
                  :label="$t('salary.jobtpe')"
                  bottom
                  v-show="!!data.tpe && data.tpe.flg_jobtpe"
                  item-text="name"
                  item-value="id"
                >
                </v-select>
              </v-col>
              <v-col>
                {{ $t('salary.filter') }}:<br />
                <div v-show="!!data.tpe && data.tpe.flg_sgroup">
                  {{ $t('salary.grpservice') }} :({{ !!data.service_group ? data.service_group.length : 0 }})<v-btn
                    small
                    @click="selForm()"
                  >
                    {{ $t('all.select') }}
                  </v-btn>
                </div>

                <div v-show="!!data.tpe && data.tpe.flg_ggroup">
                  {{ $t('salary.grpgood') }}:({{ !!data.good_group ? data.good_group.length : 0 }})<v-btn
                    small
                    @click="selgForm()"
                  >
                    {{ $t('all.select') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" v-if="btnDel" @click="del">
            {{ $t('all.delete') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click.native="$emit('close')"> {{ $t('all.close') }} </v-btn>
          <v-btn color="primary" dark @click.native="save">
            {{ $t('all.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import selList from './../../components/selList.vue'
import confirm from './../../components/confirm.vue'
import { create } from 'vue-modal-dialogs'
const selListBox = create(selList)
const confirmBox = create(confirm)
export default {
  props: ['view'],
  data() {
    return {
      dialog: true,
      valid: false,
      data: [],
      tpes: [],
      job_tpes: [],
      groups: [],
      selModal: false,
      selGroupList: [],
      btnDel: false,
      rate_tpe: '',
    }
  },
  computed: {},
  mounted: function() {
    var t = this
    this.btnDel = this.view.id ? true : false
    if (!this.view.id) {
      t.data.rate_tpe = 1
    }
    t.$store.commit('loadingStartShow', true)
    this.$http.post(this.$store.state.apiUrl + 'salary/allSpr/').then(
      response => {
        t.tpes = response.body.tpe
        t.job_tpes = response.body.jobtpe
        t.groups = response.body.group
        if (t.view.id) {
          t.$http.post(t.$store.state.apiUrl + 'salary/salary/' + t.view.id).then(
            response => {
              t.$store.commit('loadingStartShow', false)
              t.data = response.body
              t.data.tpe = t.tpes.filter(e => e.id == t.data.tpe)[0]
            },
            err => {
              console.log(err)
            },
          )
        } else {
          t.$store.commit('loadingStartShow', false)
          t.data = t.view
        }
      },
      err => {
        console.log(err)
      },
    )
  },
  methods: {
    selForm: function() {
      var t = this
      console.log('t.data.service_group', t.data.service_group)
      selListBox({
        urlLoad: 'salary/selGroup/',
        prmLoad: { id: t.data.id },
        sel: t.data.service_group,
        title: t.$t('salary.grpservicesel'),
      })
        .transition()
        .then(response => {
          if (response) {
            t.$set(t.data, 'service_group', response)
            console.log('response', response)
            console.log('t.data.service_group2', t.data.service_group)
          }
        })
    },
    selgForm: function() {
      var t = this
      console.log('t.data.good_group', t.data.good_group)
      selListBox({
        urlLoad: 'salary/selgGroup/',
        prmLoad: { id: t.data.id },
        sel: t.data.good_group,
        title: t.$t('salary.grpgoodsel'),
      })
        .transition()
        .then(response => {
          if (response) {
            t.$set(t.data, 'good_group', response)
            console.log('response', response)
            console.log('t.data.good_group2', t.data.good_group)
          }
        })
    },
    selStForm: function() {
      var t = this
      console.log('t.data.status', t.data.status)
      selListBox({
        urlLoad: 'salary/selStatus/',
        prmLoad: { id: t.data.id },
        sel: t.data.status,
        title: t.$t('salary.statussel'),
      })
        .transition()
        .then(response => {
          if (response) {
            t.$set(t.data, 'status', response)
            console.log('response', response)
            //                        console.log('t.data.good_group2', t.data.good_group);
          }
        })
    },
    del: function() {
      var t = this
      console.log('confirmBox', confirmBox)
      confirmBox({
        text: t.$t('salary.delconfirm'),
        type: 'delete',
        title: t.$t('salary.titledel'),
      })
        .transition()
        .then(response => {
          if (response) {
            this.$http.post(this.$store.state.apiUrl + 'salary/del/', { id: t.data.id }).then(
              response => {
                t.$store.dispatch('setMessage', {
                  type: response.body.err == 1 ? 'error' : 'success',
                  message: t.$t(response.body.msg),
                })
                if (!response.body.err) {
                  t.$emit('close', 'reload')
                }
              },
              err => {
                console.log(err)
              },
            )
          }
        })
    },
    save: function() {
      var t = this
      t.data.rate_tpe = t.rate_tpe
      t.$store.commit('loadingStartShow', true)
      var arr = Object.assign({}, this.data)

      console.log(this.data)
      console.log(arr)
      if (this.valid) {
        this.$http.post(this.$store.state.apiUrl + 'salary/save/', arr).then(
          response => {
            t.$store.commit('loadingStartShow', false)
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: t.$t(response.body.msg),
            })
            if (!response.body.err) {
              this.$emit('close', 'reload')
            }
            //                    t.ResultLogin=response.body;
            //                    this.$store.commit('mLogin', t.ResultLogin)
            //                    console.log(this.$route.query.redirect);
            //                    console.log(this.redirectToAfterLogin);
            //                    this.$router.push(this.redirectToAfterLogin)
          },
          err => {
            console.log(err)
          },
        )
      }
      //                console.log(this.resources);
      //                console.log(this.view);

      //            this.modal='edit';
      //            this.modalTitle = 'Добавление роли';
      //            this.modalView='';
      //                        this.showModal = true;
      //                        this.modalContent = this.tpl_edit;
      //                      $('#'+this.tag).modal('show')
    },
  },
}
</script>

<style>
.modal-body {
  max-height: calc(100vh - 212px);
  overflow: auto;
}
</style>
