import { ref, reactive, onMounted } from '@vue/composition-api'
import axios from 'axios'

import CEdit from './../../components/edit.vue'
import { create } from 'vue-modal-dialogs'
import libStore from './../../store'
import i18n from '@/i18n'
const messageBoxE = create(CEdit)

export function useTest() {
  const sGrp = reactive({ groups: ['we', 'gg'] })
  const groups = ref([])
  const group = ref('')
  const store = libStore
  const groupLoad = () => {
    store.commit('loadingStartShow', true)

    axios.post(store.state.apiUrl + 'salary/group').then(
      response => {
        store.commit('loadingStartShow', false)
        if (!response.data.err) {
          groups.value = response.data
        }
      },
      err => {
        console.log(err)
      },
    )
  }
  const selGroup = grp => {
    group.value = grp
  }
  onMounted(groupLoad)
  const edit = grp => {
    messageBoxE({
      urlSave: 'salary/groupSave/',
      urlDel: 'salary/groupDelete/',
      id: grp.id,
      fields: [
        {
          type: 'input',
          text: i18n.t('salary.grpname'),
          name: 'name',
          value: grp.name,
        },
      ],
      title: i18n.t('salary.grpedittitle'),
      titleDel: i18n.t('salary.grpdeltitle'),
      delMsg: i18n.t('salary.grpdelconfirm', [grp.name]),
    })
      .transition()
      .then(response => {
        if (response) {
          groupLoad()
        }
        console.log('response', response)
      })
  }
  return { groupLoad, selGroup, groups, group, edit }
}
