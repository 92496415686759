<template>
  <v-dialog v-model="dialog" width="600" scrollable>
    <v-card>
      <v-card-title v-if="title" class="headline">{{ title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="text-left">Название</th>
              <th class="text-left" width="100px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in selList" :key="item.id" v-bind:class="{ success: item.flg }">
              <td>{{ item.name }}</td>
              <td>
                <v-btn v-if="!!!item.flg" small @click="item.flg = true">Выбрать</v-btn>
                <v-btn v-if="!!item.flg" small @click="item.flg = false">Снять</v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text @click="$close(false)"> Закрыть </v-btn>
        <v-btn color="primary" dark @click="save()"> Выбрать </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    urlLoad: String,
    prmLoad: Object,
    sel: Array,
  },
  data() {
    return {
      dialog: true,
      selList: [],
    }
  },
  mounted: function() {
    var t = this
    this.$http.post(this.$store.state.apiUrl + this.urlLoad, t.prmLoad).then(
      response => {
        if (t.sel != undefined && t.sel.length > 0) {
          response.body.forEach(function(entry) {
            //                            console.log('entry',entry);
            //                            console.log('t.sel',t.sel);
            if (t.sel.indexOf(entry.id) >= 0) {
              //            response.body[entry]['flg']=true;
              entry.flg = true
              // console.log('+++++',response.body[entry]);
            } else {
              entry.flg = false
              //                              response.body[entry]['flg']=false;
            }
          })
        }
        t.selList = response.body
      },
      err => {
        console.log(err)
      },
    )
  },
  methods: {
    save: function() {
      var t = this
      var ret = []
      t.selList.forEach(function(entry) {
        if (entry.flg) {
          ret.push(entry.id)
        }
      })
      t.$close(ret)
    },
  },
}
</script>

<style></style>
